var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('VacationScheduleModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "elementId": _vm.modalData.existingItemId,
      "existingItemModal": _vm.modalData.existingItem
    },
    on: {
      "close": function close($event) {
        return _vm.closeModal();
      }
    }
  })], 1) : _vm._e(), _c('v-form', [_c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" Skolerute ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk i skoleruter",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _vm.canUpsert ? _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "openNewScehdule"
          },
          on: {
            "click": _vm.openNewSchedule
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Ny skolerute ")], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_c('BaseTableFiltered', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.schedules,
      "search": _vm.search,
      "filter": _vm.filter
    },
    scopedSlots: _vm._u([{
      key: "item.isActive",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.openDetailsSchedule(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("vis skolerute")])]), _vm.canUpsert ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.openEditSchedule(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger skolerute")])]) : _vm._e()];
      }
    }], null, true)
  })]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }