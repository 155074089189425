
import { api } from "@/api/api";
import { ApiCreateHolidayDto, ApiGetHolidayDto, ApiUpdateHolidayDto } from "@/api/generated/Api";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "VacationScheduleModal",
  components: { BaseModalForm, BaseDatePicker, BaseTooltipIconButton },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    existingItemModal: {
      type: Object as PropType<ApiGetHolidayDto>,
    },
    elementId: {
      type: Number,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const scheduleModel = ref<ApiCreateHolidayDto>({
      name: "",
      isActive: true,
      details: [],
    });

    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        return;
      }
      if (props.modalType === ModalType.Add) {
        addVacationScedule();
      } else if (props.modalType === ModalType.Edit) {
        editVacationScedule();
      } else {
        openNotification(store, NotificationItemType.Error, "Det skjedde en feil");
      }
    };

    const addVacationScedule = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (
          scheduleModel.value.details?.map((x) => x.toDate).includes("") ||
          scheduleModel.value.details?.map((x) => x.fromDate).includes("")
        ) {
          openNotification(store, NotificationItemType.Warning, "Kan ikke ha tom dato");
          return;
        }

        await api.holiday.createHolidayAsync(scheduleModel.value);
        openNotification(store, NotificationItemType.Success, "Skolerute opprettet");
        emit("close");
      });
    };

    const editVacationScedule = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!props.elementId) {
          throw new Error("Missing id on edit");
        }
        const updateValues: ApiUpdateHolidayDto = {
          ...scheduleModel.value,
          details: scheduleModel.value.details?.map((current) =>
            // temp "hack" untill backend makes id nullable: 10682
            ({
              ...current,
              id: (current as any).id || 0,
            })
          ),
        };
        await api.holiday.updateHolidayAsync(props.elementId, updateValues);
        openNotification(store, NotificationItemType.Success, `${scheduleModel.value.name} oppdatert`);
        emit("close");
      });
    };

    if (props.existingItemModal && (props.modalType === ModalType.Edit || props.modalType === ModalType.Display)) {
      scheduleModel.value = deepCloneObject(props.existingItemModal);
    }

    const addRow = () => {
      if (!scheduleModel.value.details) {
        scheduleModel.value.details = [];
      }
      scheduleModel.value.details.push({
        periodName: "",
        fromDate: "",
        toDate: "",
      });
    };

    const removeDetailsRow = (index: number) => {
      if (!scheduleModel.value.details) {
        return;
      }
      scheduleModel.value.details.splice(index, 1);
    };

    if (props.modalType === ModalType.Add) {
      addRow();
    }

    return {
      scheduleModel,
      handleSubmit,
      readonly: computed(() => props.modalType === ModalType.Display),
      addRow,
      removeDetailsRow,
      validateNotEmpty,
    };
  },
});
