var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Navn",
      "rules": [function (v) {
        return !!v || 'Fyll inn navn på skolerute.';
      }],
      "required": ""
    },
    model: {
      value: _vm.scheduleModel.name,
      callback: function callback($$v) {
        _vm.$set(_vm.scheduleModel, "name", $$v);
      },
      expression: "scheduleModel.name"
    }
  })], 1)], 1), _vm._l(_vm.scheduleModel.details, function (period, index) {
    return _c('v-row', {
      key: index
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": _vm.readonly ? 6 : 5,
        "xs": "12"
      }
    }, [_c('v-text-field', {
      attrs: {
        "readonly": _vm.readonly,
        "label": "Navn på periode",
        "rules": [function (v) {
          return !!v || 'Fyll inn navn på periode.';
        }],
        "required": ""
      },
      model: {
        value: period.periodName,
        callback: function callback($$v) {
          _vm.$set(period, "periodName", $$v);
        },
        expression: "period.periodName"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "3",
        "xs": "12"
      }
    }, [_c('BaseDatePicker', {
      attrs: {
        "readonly": _vm.readonly,
        "label": "Fra",
        "rules": _vm.validateNotEmpty
      },
      model: {
        value: period.fromDate,
        callback: function callback($$v) {
          _vm.$set(period, "fromDate", $$v);
        },
        expression: "period.fromDate"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "3",
        "xs": "12"
      }
    }, [_c('BaseDatePicker', {
      attrs: {
        "readonly": _vm.readonly,
        "label": "Til",
        "rules": _vm.validateNotEmpty,
        "min": period.fromDate
      },
      model: {
        value: period.toDate,
        callback: function callback($$v) {
          _vm.$set(period, "toDate", $$v);
        },
        expression: "period.toDate"
      }
    })], 1), index > 0 && !_vm.readonly ? _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "1",
        "xs": "12"
      }
    }, [_c('BaseTooltipIconButton', {
      attrs: {
        "color": "primary",
        "btnText": "Slett rad",
        "icon": "mdi-delete"
      },
      on: {
        "handleClick": function handleClick($event) {
          return _vm.removeDetailsRow(index);
        }
      }
    })], 1) : _vm._e()], 1);
  }), !_vm.readonly ? _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.addRow
    }
  }, [_vm._v("Legg til rad")]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "value": false,
      "inset": "",
      "label": _vm.scheduleModel.isActive ? 'Er aktiv' : 'Inaktiv'
    },
    model: {
      value: _vm.scheduleModel.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.scheduleModel, "isActive", $$v);
      },
      expression: "scheduleModel.isActive"
    }
  })], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }